import { render, staticRenderFns } from "./merit.vue?vue&type=template&id=9127c2ac&scoped=true"
import script from "./merit.vue?vue&type=script&lang=js"
export * from "./merit.vue?vue&type=script&lang=js"
import style0 from "./merit.vue?vue&type=style&index=0&id=9127c2ac&prod&lang=less&scoped=scoped"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9127c2ac",
  null
  
)

export default component.exports